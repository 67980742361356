import { IntlShape } from 'react-intl'
import { KEYS } from './constant'

export interface OptionShape {
    key: string
    description: string,
    checked: boolean
}

export const translate = (intl: IntlShape, translation: string, isCapitalize = true) => {
    if (intl.messages) {
        const entry = Object.entries(KEYS).find((e) => e[1] === translation)
        if (entry === undefined) console.warn(`${translation} do not have a key`)
        const id: string = entry ? entry[0] : translation
        const text = intl.formatMessage({ id, defaultMessage: translation })
        if (isCapitalize) return text.charAt(0).toUpperCase() + text.slice(1)

        return text
    } else {
        console.error('No translations available')
        return translation
    }
}

export const formatTransKey = (text: string) => text?.replaceAll(/\s+|[.,:]+/g, '')?.replaceAll(String.fromCharCode(10), '')

export const formatOptionKey = (key: string, description: string) => formatTransKey(`${key}DESCRIPTION|${description}`)

export const translateOption = (intl: IntlShape, option: OptionShape, isCapitalize = true) => {
    if (!intl.messages) {
        console.error('No translations available')
        return option.description
    }
    const formattedKey = formatTransKey(option.key)
    const translationKey = Object.keys(intl.messages).includes(formattedKey)
        ? formattedKey
        : formatOptionKey(option.key, option.description)

    return translate(intl, translationKey, isCapitalize)
}
