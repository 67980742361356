import React, { useEffect, useState, useCallback } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { visuallyHidden } from '@mui/utils'
import LinearProgress from '@mui/material/LinearProgress'
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    TableRow,
    TableSortLabel,
    Typography,
    Paper,
    Button,
    Grid,
    Radio,
    ButtonGroup,
    CircularProgress,
    Pagination,
    CardMedia,
} from '@mui/material'
import { KEYS } from 'utils/intl/constant'
import { translate } from 'utils/intl/helper'
import { decompress } from 'object-compressor'
import { getFilename } from '../../utils/functions'
import { addSelected, removeSelected, selectSelected } from '../../../store/reducer/candidate'
import { addList as reportAddList } from '../../../store/reducer/report'
import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import constant from '../../utils/constant'
import { setLoading, selectTheme, loading } from '../../../store/reducer/ui'
import Filter from '../../page/left/Filter'
import Content from '../../component/template/Content'
import Aside from '../../component/template/Aside'
const StyledCardMedia = styled(CardMedia)`
    && {
        object-fit: contain;
        height: 120px;
    }
`

const StyledBox = styled(Box)`
    .MuiTableCell-root {
        color: #757575;
    }
`

const WrapInfo = styled.div`
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
`

const Label = styled.span`
    background: rgba(192, 192, 192, 0.218);
    padding: 5px;
    margin: 0 5px;
    border-radius: 10px;
`

const TextInfo = styled(Typography)`
    && {
        color: #757575;
        font-size: 14px;
    }
`

const TextError = styled(Typography)`
    && {
        color: #ff0000;
        font-size: 14px;
    }
`

const StyledLoading = styled.div`
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
`

interface Data {
    size: string
    option: string
    status: string
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

interface EnhancedTableProps {
    numSelected: number
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
    order: Order
    orderBy: string
    rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const intl = useAppSelector((state) => state.ui.intl)
    const { order, orderBy, onRequestSort } = props
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property)
    }

    interface HeadCell {
        disablePadding: boolean
        id: keyof Data
        label: string
        numeric: boolean
    }

    const headCells: readonly HeadCell[] = [
        {
            id: '',
            numeric: false,
            disablePadding: false,
            label: '',
        },
        {
            id: 'size',
            numeric: false,
            disablePadding: false,
            label: translate(intl, KEYS.UI_SIZE),
        },
        {
            id: 'option',
            numeric: false,
            disablePadding: false,
            label: translate(intl, KEYS.UI_OPTION),
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: translate(intl, KEYS.UI_LASTUPDATE),
        },
    ]

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox' />
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='right'
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component='span' sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

interface EnhancedTableToolbarProps {
    defaultData: any
    currentRangeIndex: string
    onRangeIndexChange: Function
}

const EnhancedTableToolbar = React.memo((props: EnhancedTableToolbarProps) => {
    const { defaultData } = props
    const [pdfBlob, setPdfBlob] = useState(null)
    const selectedData = useAppSelector((state) => selectSelected(state.candidate))
    const intl = useAppSelector((state) => state.ui.intl)
    const selectedLang: string = useAppSelector((state) => state.ui.lang)
    const dispatch = useAppDispatch()
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState(null)
    const getPdfBlob = async (type) => {
        setLoader(true)
        setError(null)
        try {
            const tdsLink =
                constant.backend.GENERATE_TDS +
                selectedData.model +
                '/' +
                selectedData.id +
                '/' +
                type +
                '/' +
                selectedData.size +
                '/' +
                selectedLang

            const response = await axios.get(tdsLink, {
                responseType: 'arraybuffer',
            })
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
            setPdfBlob(pdfBlob)
            setLoader(false)
        } catch (error) {
            console.error(error)
            setError(error)
            setLoader(false)
        }
    }

    const handleClickButton = (type: string) => {
        getPdfBlob(type)
    }
    useEffect(() => {
        if (pdfBlob) {
            dispatch(
                reportAddList({
                    pdfBlob,
                    model: selectedData.info?.modelname ?? selectedData.model?.toUpperCase(),
                    size: selectedData.size,
                })
            )
        }
    }, [pdfBlob])

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        margin: '0 8px',
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }))

    const checkCalctype = (calcType: string) => {
        const ecodesignCalcType = selectedData
            ? Object.keys(selectedData?.ecodesign).filter((name) => name.includes(calcType))
            : []

        if (!ecodesignCalcType.length) console.warn(`missing ecodesign ${calcType}`)

        return ecodesignCalcType
    }

    const seer = checkCalctype('SEER')
    const sepr = checkCalctype('SEPR')
    const scop = checkCalctype('SCOP').reduce(
        (prev, curr) => ({
            ...prev,
            LT: { ...prev.LT, values: [...prev.LT.values, ...(curr.includes('LT') ? [curr] : [])] },
            MT: { ...prev.MT, values: [...prev.MT.values, ...(curr.includes('MT') ? [curr] : [])] },
            HT: { ...prev.HT, values: [...prev.HT.values, ...(curr.includes('HT') ? [curr] : [])] },
        }),
        {
            LT: { name: KEYS.UI_LOWTEMPERATURE as string, values: [] as string[] },
            MT: { name: KEYS.UI_MEDIUMTEMPERATURE as string, values: [] as string[] },
            HT: { name: KEYS.UI_HIGHTEMPERATURE as string, values: [] as string[] },
        }
    )
    return (
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    {defaultData && (
                        <Grid container>
                            <Grid item xs={6}>
                                <Item variant='outlined' square>
                                    <StyledCardMedia
                                        component='img'
                                        image={`${constant.ui.STATIC_ASSETS_STORAGE_URL}${getFilename(
                                            defaultData.info.image.modelImage
                                        )}`}
                                    />
                                </Item>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={6}>
                    {seer?.length > 0 && (
                        <WrapInfo>
                            <TextInfo sx={{ flex: '1 1 100%' }}>{translate(intl, KEYS.UI_COMFORT)}</TextInfo>
                            <ButtonGroup fullWidth>
                                {seer.map((calctype, index) => (
                                    <Button key={index} onClick={() => handleClickButton(calctype)}>
                                        {translate(intl, 'UI_' + calctype.toUpperCase())}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </WrapInfo>
                    )}
                    {sepr?.length > 0 && (
                        <WrapInfo>
                            <TextInfo sx={{ flex: '1 1 100%' }}>{translate(intl, KEYS.UI_PROCESS)}</TextInfo>
                            <ButtonGroup fullWidth>
                                {sepr.map((calctype, index) => (
                                    <Button key={index} onClick={() => handleClickButton(calctype)}>
                                        {translate(intl, 'UI_' + calctype.toUpperCase())}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </WrapInfo>
                    )}
                    {(scop.LT.values.length > 0 || scop.MT.values.length > 0 || scop.HT.values.length > 0) && (
                        <WrapInfo>
                            <TextInfo sx={{ flex: '1 1 100%' }}>{translate(intl, KEYS.UI_SPACEHEATER)}</TextInfo>
                            {Object.values(scop).map((v) => {
                                return (
                                    v.values.length > 0 && (
                                        <>
                                            <TextInfo sx={{ flex: '1 1 100%' }}>{translate(intl, v.name)}</TextInfo>
                                            <ButtonGroup fullWidth>
                                                {v.values.map((calctype, index) => (
                                                    <Button key={index} onClick={() => handleClickButton(calctype)}>
                                                        {translate(intl, 'UI_' + calctype.toUpperCase())}
                                                    </Button>
                                                ))}
                                            </ButtonGroup>
                                        </>
                                    )
                                )
                            })}
                        </WrapInfo>
                    )}
                </Grid>
            </Grid>
            {loader && (
                <Box>
                    <LinearProgress style={{ marginTop: '2em' }} />
                    <TextInfo display='flex' justifyContent='center'>
                        {translate(intl, KEYS.UI_TDSGENERATION_PROGRESS)}
                    </TextInfo>
                </Box>
            )}
            {error && (
                <Box>
                    <TextError display='flex' justifyContent='center'>
                        {translate(intl, KEYS.UI_TDSGENERATION_ERROR)}
                    </TextError>
                </Box>
            )}
        </Box>
    )
})

export default function EnhancedTable() {
    const isLoading = useAppSelector((state) => loading(state.ui))

    const [order, setOrder] = React.useState<Order>('asc')
    const [orderBy, setOrderBy] = React.useState<keyof Data>('size')
    const [selected, setSelected] = React.useState<string>('')
    const [page, setPage] = React.useState(1)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [totalRows, setTotalRows] = useState(0)
    const { selectedModel } = useParams()
    const dispatch = useAppDispatch()
    const intl = useAppSelector((state) => state.ui.intl)
    const [listFiltered, setListFiltered] = useState([])

    const [checkedOptions, setCheckedOptions] = React.useState([])
    const [defaultOptions, setDefaultOptions] = React.useState([])
    const [defaultSizes, setDefaultSizes] = React.useState([])
    const [checkedSizes, setCheckedSizes] = React.useState([])
    const [defaultCalcTypes, setDefaultCalcTypes] = React.useState([])
    const [checkedCalcTypes, setCheckedCalcTypes] = React.useState([])
    const StyledGrid = styled(Grid)`
        min-height: calc(100vh - 190px);
        @media (max-width: 1200px) {
            min-height: calc(100vh - 280px);
        }
    `
    const findSelected = (selectedId: string) => listFiltered?.find(({ id }) => id === selectedId)
    const isCiat = useAppSelector((state) => selectTheme(state.ui))
    const [currentRangeIndex, setCurrentRangeIndex] = React.useState<string>('')
    const fetchOptions = useCallback(async () => {
        try {
            const { data } = await axios.get(constant.backend.OPTIONS_BY_MODEL + selectedModel, {
                params: {
                    company: isCiat ? 'ciat' : 'carrier',
                },
            })
            const uniqueOption = [...new Set(data.map(JSON.stringify))].map(JSON.parse)
            const checkedOptions = uniqueOption.map((option) => ({ ...option, checked: false }))
            setDefaultOptions(uniqueOption)
            setCheckedOptions(checkedOptions)
        } catch (error) {
            console.error(error)
        }
    }, [])

    const fetchSizes = useCallback(async () => {
        try {
            const { data } = await axios.get(constant.backend.SIZES_BY_MODEL + selectedModel, {
                params: {
                    company: isCiat ? 'ciat' : 'carrier',
                },
            })
            const uniqueSizes = [...new Set(data.map(JSON.stringify))].map(JSON.parse)
            const checkedSizes = uniqueSizes.map((size) => ({ label: size, checked: false }))
            setDefaultSizes(uniqueSizes)
            setCheckedSizes(checkedSizes)
        } catch (error) {
            console.error(error)
        }
    }, [])

    const fetchCalcTypes = useCallback(async () => {
        try {
            const { data } = await axios.get(constant.backend.CALC_TYPES_BY_MODEL + selectedModel, {
                params: {
                    company: isCiat ? 'ciat' : 'carrier',
                },
            })
            const uniqueCalcTypes = [...new Set(data.map(JSON.stringify))].map(JSON.parse)
            const checkedCalcTypes = uniqueCalcTypes.map((type) => ({ label: type, checked: false }))
            setDefaultCalcTypes(uniqueCalcTypes)
            setCheckedCalcTypes(checkedCalcTypes)
        } catch (error) {
            console.error(error)
        }
    }, [])
    const fetchData = useCallback(
        async (checkedOptions, checkedSizes, checkedCalcTypes) => {
            dispatch(setLoading(true))
            let params = {
                company: isCiat ? 'ciat' : 'carrier'
            }
            if (checkedOptions.filter((o) => o.checked).length > 0) {
                params = {
                    ...params,
                    ...{
                        options: [
                            ...checkedOptions.filter((option) => option.checked).map((option) => option.key),
                        ].join(','),
                    },
                }
            }
            if (checkedSizes.filter((s) => s.checked).length > 0) {
                params = {
                    ...params,
                    ...{
                        sizes: [...checkedSizes.filter((size) => size.checked).map((size) => size.label)].join(','),
                    },
                }
            }
            if (checkedCalcTypes.filter((c) => c.checked).length > 0) {
                params = {
                    ...params,
                    ...{
                        types: [...checkedCalcTypes.filter((type) => type.checked).map((type) => type.label)].join(','),
                    },
                }
            }
            try {
                const { data } = await axios.get(
                    constant.backend.SEARCH_MODEL + selectedModel + `/${rowsPerPage}/${page}`,
                    {
                        params,
                    }
                )
                if (totalRows === 0) {
                    setTotalRows(data.totalResult)
                }
                const resultData = data?.result?.map((obj) => decompress(obj))
                setListFiltered(resultData)
                setSelected('')
            } catch (error) {
                console.error(error)
            } finally {
                dispatch(setLoading(false))
            }
        },
        [dispatch, page]
    )

    useEffect(() => {
        fetchCalcTypes()
        fetchOptions()
        fetchSizes()
    }, [])

    useEffect(() => {
        fetchData(checkedOptions, checkedSizes, checkedCalcTypes)
    }, [checkedSizes, checkedOptions, fetchData, checkedCalcTypes])

    const handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleClick = (_: React.MouseEvent<unknown>, id: string) => {
        setSelected(selected !== id ? id : '')
        dispatch(selected !== id ? addSelected(findSelected(id)) : removeSelected())
    }

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage)
    }

    const isSelected = (name: string) => selected.indexOf(name) !== -1

    const [expanded, setExpanded] = React.useState('option')

    return (
        <StyledGrid container>
            <Aside>
                <Filter
                    checkedOptions={checkedOptions}
                    setCheckedOptions={setCheckedOptions}
                    checkedSizes={checkedSizes}
                    setCheckedSizes={setCheckedSizes}
                    checkedCalcTypes={checkedCalcTypes}
                    setCheckedCalcTypes={setCheckedCalcTypes}
                    expanded={expanded}
                    setExpanded={setExpanded}
                />
            </Aside>
            <Content>
                {isLoading ? (
                    <StyledLoading>
                        <CircularProgress disableShrink />
                    </StyledLoading>
                ) : listFiltered.length > 0 ? (
                    <StyledBox>
                        <EnhancedTableToolbar
                            defaultData={listFiltered[0]}
                            currentRangeIndex={currentRangeIndex}
                            onRangeIndexChange={setCurrentRangeIndex}
                        />

                        <Paper elevation={0}>
                            <TableContainer>
                                <Table>
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={() => {}}
                                        onRequestSort={handleRequestSort}
                                        rowCount={listFiltered.length}
                                    />
                                    <TableBody>
                                        {listFiltered?.length
                                            ? stableSort(listFiltered, getComparator(order, orderBy)).map(
                                                  (row, index) => {
                                                      const isItemSelected = isSelected(row.id)
                                                      const options = row.options.map((o) => ({
                                                          key: o.Key,
                                                          description: o.Description,
                                                          shortDescription: o.ShortDescription,
                                                      }))

                                                      return (
                                                          <>
                                                              <TableRow
                                                                  hover
                                                                  onClick={(event) => handleClick(event, row.id)}
                                                                  role='checkbox'
                                                                  aria-checked={isItemSelected}
                                                                  tabIndex={-1}
                                                                  key={index}
                                                                  selected={isItemSelected}>
                                                                  <TableCell></TableCell>
                                                                  <TableCell padding='checkbox'>
                                                                      <Radio color='primary' checked={isItemSelected} />
                                                                  </TableCell>
                                                                  <TableCell align='right'>{row.size}</TableCell>
                                                                  <TableCell align='right'>
                                                                      {options.map((option, index) => (
                                                                          <Label key={index}>
                                                                              {option.shortDescription}
                                                                          </Label>
                                                                      ))}
                                                                  </TableCell>
                                                                  <TableCell align='right'>
                                                                      {new Date(
                                                                          row.info.updated_at
                                                                      ).toLocaleDateString()}
                                                                  </TableCell>
                                                              </TableRow>
                                                          </>
                                                      )
                                                  }
                                              )
                                            : Array(rowsPerPage).map((_, index) => (
                                                  <TableRow key={index}>
                                                      <TableCell colSpan={6} />
                                                  </TableRow>
                                              ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display='flex' justifyContent='flex-end' pt={3}>
                                <Pagination
                                    page={page}
                                    onChange={handleChangePage}
                                    count={Math.round(totalRows / 10)}
                                    variant='outlined'
                                    color='primary'
                                    shape='rounded'
                                />
                            </Box>
                        </Paper>
                    </StyledBox>
                ) : (
                    <StyledBox>
                        <Paper elevation={0}>
                            <Typography>{translate(intl, KEYS.UI_NO_RESULTS_FOUND)}</Typography>
                        </Paper>
                    </StyledBox>
                )}
            </Content>
        </StyledGrid>
    )
}
