import React, { useEffect } from 'react'
import Box from '@mui/material/Box'

import { Collapse, Typography } from '@mui/material'
import styled from 'styled-components'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowRight'

import ListItemText from '@mui/material/ListItemText'

import { translate, translateOption } from 'utils/intl/helper'
import { KEYS } from 'utils/intl/constant'
import { addListFiltred, selectList } from '../../../store/reducer/candidate'
import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import { selectTheme } from '../../../store/reducer/ui'

const Container = styled.div`
    display: flex;
    width: 100%;
`

const StyledBox = styled(Box)`
    color: #0000008a;
    width: 100%;
`

const StyledFormControlLabel = styled(FormControlLabel)`
    margin-bottom: 16px;
`

const StyledFormGroup = styled(FormGroup)`
    padding: 10px;
`

const StyledList = styled(List)`
    border-bottom: 1px solid #e5e7ed;
`

const StyledListFirst = styled(List)`
    border-bottom: 1px solid #e5e7ed;
    border-top: 1px solid #e5e7ed;
`

const TextInfo = styled(Typography)`
    && {
        color: #757575;
        font-size: 14px;
    }
`
const CheckboxLabels = ({ label, name, checked, onChange }) => {
    return (
        <StyledFormControlLabel
            labelPlacement='end'
            control={<Checkbox {...{ checked, onChange, name: name }} size='small' />}
            {...{ label: <TextInfo>{label}</TextInfo> }}
        />
    )
}

export default function NonLinearSlider({
    checkedOptions,
    setCheckedOptions,
    checkedSizes,
    setCheckedSizes,
    checkedCalcTypes,
    setCheckedCalcTypes,
    expanded,
    setExpanded
}) {
    const intl = useAppSelector((state) => state.ui.intl)
    const handleExpandClick = (category: string) => {
        setExpanded(category)
    }

    const toggleChangeOption = (event, value) => {
        setCheckedOptions((prev) => {
            const newState = prev.map((obj) => {
                if (obj.key === event.target.name) {
                    return { ...obj, checked: value }
                }
                return obj
            })
            return newState
        })
    }

    const toggleChangeAll = (setState) => {
        setState((prev) => {
            if (prev.map((obj) => obj.checked).every(Boolean)) {
                return prev.map((obj) => ({ ...obj, checked: false }))
            } else {
                return prev.map((obj) => ({ ...obj, checked: true }))
            }
        })
    }
    const toggleChangeSize = (event, value) => {
        setCheckedSizes((prev) => {
            const newState = prev.map((obj) => {
                if (obj.label === event.target.name) {
                    return { label: obj.label, checked: value }
                }
                return obj
            })
            return newState
        })
    }

    const toggleChangeType = (event, value) => {
        setCheckedCalcTypes((prev) => {
            const newState = prev.map((obj) => {
                if (obj.label === event.target.name) {
                    return { label: obj.label, checked: value }
                }
                return obj
            })
            return newState
        })
    }

    return (
        <Container>
            <StyledBox>
                <StyledListFirst disablePadding>
                    <ListItem
                        onClick={() => handleExpandClick('option')}
                        secondaryAction={expanded === 'option' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        disablePadding>
                        <ListItemButton selected={expanded === 'option'}>
                            <ListItemText primary={translate(intl, KEYS.UI_OPTION)} />
                        </ListItemButton>
                    </ListItem>
                </StyledListFirst>

                <Collapse in={expanded === 'option'} timeout='auto'>
                    <StyledFormGroup>
                        {checkedOptions && (
                            <>
                                <CheckboxLabels
                                    key={'SelectAllOptions'}
                                    label={translate(intl, KEYS.UI_SELECTALLOPTIONS)}
                                    name={'SelectAllOptions'}
                                    checked={checkedOptions.map((o) => o.checked).every(Boolean)}
                                    onChange={() => toggleChangeAll(setCheckedOptions)}
                                />
                                <Divider />
                            </>
                        )}
                        {checkedOptions &&
                            checkedOptions.map((option) => (
                                <>
                                    <CheckboxLabels
                                        key={option.key}
                                        label={`${option.shortDescription} - ${translateOption(intl, option)}`}
                                        name={option.key}
                                        checked={option.checked}
                                        onChange={toggleChangeOption}
                                    />
                                </>
                            ))}
                    </StyledFormGroup>
                </Collapse>

                <StyledList disablePadding>
                    <ListItem
                        onClick={() => handleExpandClick('size')}
                        secondaryAction={expanded === 'size' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        disablePadding>
                        <ListItemButton selected={expanded === 'size'}>
                            <ListItemText primary={translate(intl, KEYS.UI_SIZE)} />
                        </ListItemButton>
                    </ListItem>
                </StyledList>

                <Collapse in={expanded === 'size'} timeout='auto'>
                    <StyledFormGroup>
                        {checkedSizes && (
                            <>
                                <CheckboxLabels
                                    key={'SelectAllSizes'}
                                    label={translate(intl, KEYS.UI_SELECTALLSIZES)}
                                    name={'SelectAllSizes'}
                                    checked={checkedSizes.map((o) => o.checked).every(Boolean)}
                                    onChange={() => toggleChangeAll(setCheckedSizes)}
                                />
                                <Divider />
                            </>
                        )}
                        {checkedSizes &&
                            checkedSizes.map((size) => (
                                <CheckboxLabels
                                    key={size}
                                    label={size.label}
                                    name={size.label}
                                    checked={size.checked}
                                    onChange={toggleChangeSize}
                                />
                            ))}
                    </StyledFormGroup>
                </Collapse>

                <StyledList disablePadding>
                    <ListItem
                        onClick={() => handleExpandClick('type')}
                        secondaryAction={expanded === 'type' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        disablePadding>
                        <ListItemButton selected={expanded === 'type'}>
                            <ListItemText primary={translate(intl, KEYS.UI_CALCTYPE)} />
                        </ListItemButton>
                    </ListItem>
                </StyledList>
                <Collapse in={expanded === 'type'} timeout='auto'>
                    <StyledFormGroup>
                        {checkedCalcTypes && (
                            <>
                                <CheckboxLabels
                                    key={'SelectAllCalcType'}
                                    label={translate(intl, KEYS.UI_SELECTCALCULATIONTYPES)}
                                    name={'SelectAllCalcType'}
                                    checked={checkedCalcTypes.map((o) => o.checked).every(Boolean)}
                                    onChange={() => toggleChangeAll(setCheckedCalcTypes)}
                                />
                                <Divider />
                            </>
                        )}
                        {checkedCalcTypes &&
                            checkedCalcTypes.map((type) => (
                                <CheckboxLabels
                                    key={type.label}
                                    label={translate(intl, `UI_${type.label.toUpperCase()}`)}
                                    name={type.label}
                                    checked={type.checked}
                                    onChange={toggleChangeType}
                                />
                            ))}
                    </StyledFormGroup>
                </Collapse>
            </StyledBox>
        </Container>
    )
}
